@import '../../styles/index';

.upload-template-file-item .ant-upload-list-item-name {
  width: 50px;
}

.card-holder {
  cursor: grab;
  font-size: 24px;
}

.card-holder:active {
  cursor: grabbing;
}

.card-input {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  outline: none;
  margin-bottom: 0.5rem;
  padding-bottom: 0.3rem;
  overflow: hidden;
  resize: none;
}

.card-input:focus {
  border-bottom: 1px solid @primary-6;
}

.card {
  width: 290px;
  height: 340px;
}

.card__add {
  color: @primary-6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 288px;
  height: 338px;
  border: 2px solid @primary-6;
  margin-right: 12px;
  margin-top: 12px;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 165px;
  padding-right: 20px;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background-color: rgba(105, 111, 121, 0.2);
  }
}

.card-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  top: -8px;
  right: -40px;
  width: 35px;
  height: 18px;
}

.steps-content {
  min-height: 200px;
  max-width: 100%;
  margin-top: 20px;
  text-align: center;
}

.steps-action {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-right: 16rem;
  margin-top: 24px;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 340px);
  row-gap: 3rem;
}

.projects-view-col {
  padding: 12px;
  border: 1px solid #f0f0f0;
}

.pagination {
  display: flex;
  justify-content: center;
  height: 34px;
  overflow-y: hidden;
  margin-top: 50px;
}

.card-list-item {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 10px;
  padding: 30px;
}

.card-item {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 200px;
  height: 120px;
  color: gray;
  cursor: pointer;
  border-radius: 15px;

  &:hover {
    border-color: #3e84ff;
  }
}

.slide__text {
  text-align: start;
  padding-bottom: 2rem;
  padding-top: 2rem;

  .slide__title {
    font-weight: 700;
    margin-bottom: 0;
  }

  .slide__description {
    color: gray;
    margin-bottom: 0;
  }
}

.project-detail-text-width {
  min-width: 500px;
}

.ant-form-item-explain-error {
  text-align: left !important;
}

.unavailable {
  opacity: 0.5;
  pointer-events: none;
}
@primary-color: #135ff6;@text-color: #171321;