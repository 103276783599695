.condition-rules {
  display: inline-grid;
  grid-template-columns: 0.3fr 1fr;
  list-style: none;
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  height: 100%;
  margin-bottom: 5px;

  .container {
    height: fit-content;
    font-size: 13px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
  }

  .symbol {
    width: 52px;
    height: 100%;
    background: #fff;
    font-size: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    font-size: 18px;
    padding: 0 3px;
    margin: 0;
  }

  .condition-type::first-letter {
    text-transform: uppercase;
  }

  .condition-value {
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .cell-background-symbol {
    height: 40px;
    width: 40px;
  }
}

.indexing-rules-title {
  padding-top: 21px;
  padding-bottom: 7px;
  padding-left: 7px;
  font-size: 14px;
}

.form-item {
  padding: 4px 0;
}

.form-item-flex {
  display: flex !important;
  .float-right {
    float: right;
  }
}

@primary-color: #135ff6;@text-color: #171321;