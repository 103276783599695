@import '~antd/dist/antd';

@import 'core/rde';
@import 'core/motion';

// @import 'fonts/fonts';

@import 'antd/antd';
@import 'react-color/react-color';
@import 'fabricjs/fabricjs';
@import '~handsontable/dist/handsontable.full.min.css';

@primary-color: #135ff6;@text-color: #171321;