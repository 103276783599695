@import '../../styles/index';

.left-panel {
  position: relative;
  overflow-y: auto;
  width: 250px;
  -webkit-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 0 5px -5px rgba(56, 41, 41, 0.5);
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
  z-index: 900;
  transition: width 0.5s ease;
  background: @white;

  &-minimized {
    width: 60px;
  }

  .left-panel-toggle {
    margin: 16px 0 16px 0;
    padding: 8px 24px;
  }
}

@primary-color: #135ff6;@text-color: #171321;