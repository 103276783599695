.ant-tabs {
  .ant-tabs-bar {
    margin: 0;
  }
  .ant-tabs-content {
    height: -webkit-calc(100% ~'-' 10px);
    height: -moz-calc(100% ~'-' 10px);
    height: calc(100% ~'-' 10px);
    .ant-tabs-tabpane {
      overflow-y: auto;

      .ant-tabs-tabpane-header {
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        background-color: white;
        height: 40px;
        box-shadow: none;
        margin-top: 10px;
      }
    }
  }
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: 0;
}

.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  margin-right: 0;
}

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0;
}
