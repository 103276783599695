.notification {
  width: 700px;
  border: 1px solid;
  box-shadow: none;

  .ant-notification-notice-close {
    display: none;
  }
}

.auth__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 800px;
  height: calc(100vh - 128px);
  overflow-y: auto;

  .auth__divider {
    display: grid;
    grid-template-columns: 420px 420px;
    grid-gap: 40px;
  }

  .auth__card {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    width: 25%;
    max-width: 450px;
    min-height: 400px;
    height: max-content;
    padding: 12px 32px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
  }

  .card__header {
    padding: 18px 0;

    h1 {
      font-size: 26px;
      font-weight: 800;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .card__base() {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card__header {
    flex: 1 0;
    .card__base;
  }

  .card__body {
    flex: 1 0;
    .card__base;
  }

  .card__footer {
    flex: 1 0;
    flex-shrink: 0;
    .card__base;
  }

  .auth__text-block--normal {
    text-align: center;
    padding: 2rem;
  }

  .auth__text-block--large {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    padding: 2rem;
  }
}

@primary-color: #135ff6;@text-color: #171321;