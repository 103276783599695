.insert {
  position: relative;
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
  .left-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 100%;
    border-right: 1px solid #ccc;
    background-color: white;
    z-index: 200;
    transition: width 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    .icon-container {
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.2s linear;
      .icon {
        color: #171321;
      }
      &:hover {
        cursor: pointer;
        background-color: #171321;
        .icon {
          color: white;
        }
      }
    }
    .icon {
      width: 64px;
      display: flex;
      justify-content: center;
      padding: 16px;
    }
    .form {
      padding: 16px;
      opacity: 0;
      width: 300px;
      position: absolute;
      top: 0;
      left: 64px;
      transition: opacity 0.5s ease-in-out;
      display: none;
    }
    .is-open {
      display: block;
      opacity: 1;
      background-color: white;
      z-index: 1000;
      height: 100%;
      transition: opacity 0.5s ease-in-out;
    }
  }
  .layover {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 199;
    background-color: black;
    opacity: 0.5;
    transition: opacity 0.5s linear;
  }
  .left-is-open {
    width: 300px;
    transition: width 0.5s ease-in-out;
    .icon {
      width: 64px;
      padding: 16px;
    }
    .form {
      display: block;
      width: 80%;
    }
  }
  .content {
    width: calc(100% - 124px);
    margin: 0 auto;
    display: flex;
    height: 100%;

    .input-table-col,
    .output-table-col {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px;
    }

    .input-table-col {
      border-right: 1px solid #ccc;
      .table-container {
        height: 100%;
        padding-bottom: 16px;
        overflow: hidden;
        position: relative;
        .centered-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .control-panel {
        width: 100%;

        .control-panel-item {
          padding: 9px 0;

          .description {
            display: inline-block;
            min-width: 180px;
            margin-right: 12px;
          }
        }
      }
      .section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;
        min-height: 50px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .header-section {
        min-height: 50px;
        margin-bottom: 16px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .output-table-col {
      .table-container {
        height: 100%;
        overflow: hidden;
        padding-bottom: 16px;
        position: relative;
        .centered-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;
        min-height: 50px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      .header-section {
        min-height: 50px;
        margin-bottom: 16px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .right-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
    background-color: white;
    height: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #ccc;
    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 64px;
      transition: background-color 0.2s linear;

      .icon {
        color: #171321;
      }
      .disabled {
        color: #ebebe4;
      }
      &:hover {
        cursor: pointer;
        background-color: #171321;
        .icon {
          color: white;
        }
      }
    }
    .disabled {
      &:hover {
        cursor: default;
        background-color: transparent;
        .icon {
          color: #ebebe4;
        }
      }
    }
    .form {
      position: absolute;
      top: 0;
      right: 64px;
      width: 300px;
      height: 100%;
      display: none;
      background-color: white;
      .data-actions-wrapper {
        padding: 16px;
        width: 100%;
      }
    }
    .is-open {
      display: block;
    }
  }
  .lower-z-index {
    z-index: 150;
  }
  .right-is-open {
    width: 300px;
  }
}

table {
  .htDimmed {
    background-color: rgb(201, 201, 201);
  }
}

h3 {
  margin: 0;
  padding: 0;
}

@primary-color: #135ff6;@text-color: #171321;