.collapse__header {
  border-bottom: 1px solid #d9d9d9;
}

h2.collapse__header--title {
  padding: 5px 0 0 0;
  font-size: 1.25rem;
  font-weight: 1000;
  text-align: center;
  margin: 0;
}

.collapse__header--informational {
  color: rgb(93, 93, 109);
  font-size: 12px;
  letter-spacing: -0.02em;
  padding: 12px;
}

@primary-color: #135ff6;@text-color: #171321;