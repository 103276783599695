@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-moz-keyframes slide-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
