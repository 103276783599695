// Override properties for custom scrollbar
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  width: 5px !important;
  background: rgba(0, 0, 0, 0.05) !important;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 1.2rem;
  }
  .action {
    padding: 0.3rem;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
}

.projects__list {
  padding: 1rem 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: col;
}

.flex-right {
  display: flex;
  justify-content: right;
  gap: 4px;
}

.flex-items-center {
  display: flex;
  align-items: center;
}

.mouse-pointer {
  cursor: pointer;
}

// .custom-input-outline {
//   margin: 0 1px;
//   outline: 1px solid #f0f0f0; /* Change the color and width as needed */
//   outline-offset: -1px !important; /* Optional: Adjust the space between the outline and the element */
//   border-radius: 5px;
//   border: none;
//   &:hover {
//     outline-color: #40a9ff; /* Change color on hover */
//   }
// }

@primary-color: #135ff6;@text-color: #171321;