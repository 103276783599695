.has-error {
  .ant-input {
    box-shadow: none !important;
  }
}

.ant-form {
  label {
    font-size: 12px;
  }
}

.ant-form-item {
  margin-bottom: 0px;
}
