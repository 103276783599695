.canvas-shapes-list {
  height: 100%;
  padding-top: 10px;
  background-color: white;

  .shape-object {
    margin: 0 10px;
    border-bottom: 1px solid white;
    cursor: pointer;

    .shape-object-container {
      margin: 5px 0;
      padding: 5px 10px;
      border-radius: 3px;
    }

    .shape-object-container-selected {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 11px rgb(33 33 33 / 20%);
    }
  }
}

.search-object > .ant-input-group > .ant-input-group-addon:last-child {
  top: -1px;
  background: none;
  border: 0;
}

.search-object > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  background: none;
  border: 0;
}

.arraow-container {
  border-bottom: 1px solid;
}

.ant-tabs-tabpane-header {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  padding: 5px 0 0 0;
  height: 40px;
  margin-bottom: 5px;
}

.close-drawer {
  float: right;
  position: relative;
  right: 1.2em;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.6);
  bottom: 0.1rem;
}

.rde-canvas-list-actions {
  padding: 1em;
}

.shapes-lists-container {
  height: 95% !important;
}

@primary-color: #135ff6;@text-color: #171321;