.ant-input-number {
  outline: none;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #d9d9d9;
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
}

.ant-input-number-focused {
  box-shadow: none;
  border-bottom: 1px solid @primary-5;
}
