@itemHeight: 42px; // navbar item height
@marginLeft: 36px; // margin between navbar items

.navbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 8px 20px;
  font-size: 16px;

  .navbar__item {
    display: flex;
    align-items: center;
    .inline__edit {
      background: black;
      border: 0;
      color: white;
      padding-left: 5px;
      padding-right: 5px;
      min-width: 50px;
      max-width: 600px;
    }
  }

  .navbar__group--left {
    display: flex;
    align-items: center;
    min-width: 130px;
    justify-content: left;
  }

  .navbar__logo {
    height: 32px;
  }
  .navbar__logo-short {
    width: 24px;
  }

  .navbar__group--center {
    display: flex;
    align-items: center;

    .navbar__link--back {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      color: white;
      box-sizing: border-box;
      width: 44px;
      height: @itemHeight;
    }

    .navbar__form {
      display: flex;
      align-items: center;
      height: 100%;
      background: transparent;
      border: 0;
      width: 200px;
    }

    .form__field {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      margin: 0 8px;
    }

    .form__input {
      background: white;
      line-height: 1rem;
      color: black;
      font-size: 16px;
      border: 0;
      padding: 0 8px;
      margin: 0;
      height: 100%;
      width: 100%;
      box-shadow: none;
      outline: 0;
    }

    .form__button--base() {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      border: 0;
      background: transparent;
      color: white;
      cursor: pointer;
    }

    .form__button--edit {
      .form__button--base;
    }

    .form__button--ok {
      .form__button--base;
      color: rgb(17, 179, 17);
    }

    .form__button--cancel {
      .form__button--base;
      color: rgb(243, 76, 76);
    }

    .navbar__span--projectName {
      color: white;
    }

    .navbar__brand {
      color: white;
      font-size: 24px;
      display: flex;
      align-items: center;
    }
  }

  .navbar__group--right {
    display: flex;
    align-items: center;
    min-width: 130px;
    justify-content: right;
    .navbar__user {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      margin-right: 8px;
      margin-left: 25px;
    }

    .navbar__link--project {
      display: flex;
      align-items: center;
      color: white;
    }
  }
}

.navbar__editor {
  background-color: #171321;
}

.navbar__signin {
  background-color: transparent;
}

.navbar__projects {
  background-color: #171321;
}

.navbar__main {
  background-color: #171321;
}

#project-name-span {
  position: absolute;
  top: -30%;
  padding-left: 5px;
  padding-right: 5px;
}

.menu__username {
  padding: 5px 12px;
  margin: 0 4px;
  background-color: rgb(205, 205, 205);
  text-decoration: underline;
}

@primary-color: #135ff6;@text-color: #171321;