.data-sources {
  .data-sources-upload {
    margin: 15px 10px;
  }
  .data-sources-list {
    margin: 15px 10px 0 10px;
  }
  .data-sources-item {
    margin-bottom: 10px;
  }
  .data-sources-compatibility {
    &.item {
      text-align: left;
    }
    &.list {
      max-height: 282px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 10px;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #000;
        border-radius: 10px;
      }
    }
    &.title {
      margin-bottom: 10px;
    }
    &.subtitle {
      margin-bottom: 5px;
    }
  }
}

.container-original-source {
  padding: 20px 10px;
}

.card-original-source {
  background: #d9d9d9;
  margin-top: 10px;
  padding: 10px;
}

@primary-color: #135ff6;@text-color: #171321;