@import '../../../styles/index';

.data-selector {
  .ant-tabs-tabpane-header {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    padding: 5px 0 0 0;
    border-bottom: 1px solid @border-color-base;
    height: 43px;
  }

  .form {
    padding: 16px;

    .input {
      margin-bottom: 16px;
      padding: 8px;
      border-radius: 2px;
    }

    .ant-space {
      padding-right: 10px;
      max-height: calc(100vh - 230px);
      overflow-y: auto;
      scrollbar-gutter: stable;
    }

    &__item {
      padding: 5px 0;
    }

    &__submit-button {
      margin: 10px 0;
      width: 100%;
      text-align: center;
    }

    &__clear-button {
      width: 100%;
      margin: 5px 0;
    }

    &__select {
      .ant-select {
        &-selector {
          max-height: 88px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 7px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #000;
            border-radius: 10px;
          }
        }
      }
      &-clear {
        right: 16px;
      }
    }

    &__dropdown {
      &_container {
        padding: 5px;
        display: flex;
        align-items: center;
        &.top {
          justify-content: space-between;
        }
        &.bottom {
          justify-content: flex-end;
        }
      }
      &_button {
        &.ok {
          background-color: #fc0;
        }
      }
    }
  }
}

@primary-color: #135ff6;@text-color: #171321;