.canvas-header-toolbar {
  .shapes-list-icon {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 4px;
    margin-right: 4px;

    // &:hover {
    //   .shapes-list-component {
    //     background: rgba(230, 232, 235, 0.62);
    //     display: block;
    //     z-index: 1;
    //     -webkit-animation: slide-right 0.3s linear;
    //     -moz-animation: slide-right 0.3s linear;
    //     animation: slide-right 0.3s linear;
    //   }
    // }
  }

  .shapes-list-component {
    position: absolute;
    display: block;
    width: 300px;
    height: -webkit-calc(100vh ~'-' 110px);
    height: -moz-calc(100vh ~'-' 110px);
    height: calc(100vh ~'-' 110px);
    padding-top: 10px;
    z-index: 100;
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    background-color: white;
    top: 90px;
    left: 256px;
  }
}

@primary-color: #135ff6;@text-color: #171321;