@import '../../../styles/index';

.data-transformation {
  .ant-tabs-tabpane-header {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .data-transformation-body {
    margin: 15px 20px 0 20px;
  }
}

@primary-color: #135ff6;@text-color: #171321;