@import '../../../../styles/index';

@slide-width: 150px;

.slides {
  width: 100%;
  height: 'fit-content';
  display: flex;
  align-items: center;
  flex-direction: column;

  .slide-container {
    position: relative;
    display: flex;
    padding: 2px 0;
    width: calc(100% - 10px);
    cursor: pointer;

    .slide-index {
      position: absolute;
      left: 8px;
      top: 6px;
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 84px;
      width: @slide-width;
      color: rgba(0, 0, 0, 0.4);
      outline: 1px solid #a5a5a5;
      margin-top: 3px;
    }
  }

  .slide-container-selected {
    outline: 2px solid @primary-6 !important;
  }

  .slide-index-selected {
    color: @primary-6;
  }
}

.slide-button-add {
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  border: 2px dashed @primary-6;
  border-radius: 8px;
  color: @primary-6;
  width: @slide-width;
  margin-top: 8px;
  height: 6rem;
  cursor: pointer;

  :first-child {
    padding-right: 2px;
  }
}

.slide-selection-tools {
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.slide-selection-container {
  overflow-x: hidden;
  max-height: calc(100vh - 174px);
}

.slide-thumbnail-container {
  height: 480px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  row-gap: 1.5rem;
  column-gap: 1rem;
  overflow-y: hidden;
  padding: 0.5rem;
}

.slide-select-icon {
  color: @primary-6;
  font-size: 36;
}

.slide-thumbnail {
  border: 1px solid #a5a5a5;
  width: 100%;
  height: 115px;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);

  &.selected {
    box-shadow: 0 0 0 2px @primary-6;
  }
}

.upload-card {
  background: #f3f3f3;
  padding: 10px;
  font-size: 0.9em;
  margin-bottom: 2em;
}

.card-text {
  margin-bottom: 0.5em;
  font-weight: bold;
  word-wrap: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  height: 34px;
  overflow-y: hidden;
}

@primary-color: #135ff6;@text-color: #171321;